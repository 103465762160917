<template>
  <section class="new_css">
    
    <!--工具条-->
    <toolbar
      :buttonList="buttonList"
      @callFunction="callFunction"
      :buttonListmsg="RegularMeetingmsg"
    ></toolbar>
    <!--列表内容-->
      <el-table
        :data="zhibiaolist"
        highlight-current-row
      :row-style="{height:'40px'}"
      :cell-style="{padding:'0px'}"
      :header-cell-style="{background:'#dce4f1',color:'#000000'}"
        @current-change="selectCurrentZhibiaoRow"
        style="width: 100%">
        <el-table-column prop="TemplateName" align="center" min-width="120px" label="指标配置名称" ></el-table-column>
        <el-table-column prop="IsPublic" label="是否公开" min-width="50px" >
          <template scope="scope">
            <el-tag v-if="!scope.row.IsPublic" type="">私有</el-tag>
            <el-tag v-else-if="scope.row.IsPublic" type="success">公开</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="UseCount" label="使用数量" align="center" min-width="50px" ></el-table-column>
        <el-table-column prop="CreatedBy" label="创建人" min-width="50px" ></el-table-column>
        <!-- <el-table-column prop="CreatedDpt" label="创建部门" ></el-table-column> -->
        <el-table-column prop="CreateTime" label="创建时间" min-width="100px" ></el-table-column>
        <el-table-column prop="Remark" label="备注"></el-table-column>
        <el-table-column prop="Operation" label="操作" min-width="90px" >
          <template scope="scope">
            <el-popover
              placement="bottom"
              width="600"
              trigger="click">
              <el-table :data="lookdetail">
                <el-table-column property="FieldName" min-width="100px" label="指标项"></el-table-column>
                <el-table-column property="FieldUnit" align="center" label="值单位"></el-table-column>
                <el-table-column property="FieldType" align="center" label="值类型">
                  <template scope="scope">
                    <span>{{formatType(scope.row.FieldType)}}</span>
                  </template>
                </el-table-column>
                <el-table-column property="FieldLength" align="center" label="值长度"></el-table-column>
                <el-table-column property="FieldMustInput" align="center" label="是否必填">
                  <template scope="scope">
                    <span>{{scope.row.FieldMustInput?'是':'否'}}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column property="FieldDefault" align="center" label="默认值"></el-table-column> -->
              </el-table>
              <el-button slot="reference" @click="handleLookTemplateDetail(scope.row.Id)" type="text">预览</el-button>
            </el-popover>
            
            <!-- <el-button @click="handleZhibiaoRename(scope.row)" type="text">重命名</el-button>
            &nbsp;&nbsp;<el-button @click="handleChoose(scope.row)" type="text">选择</el-button> -->

          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination align='center'
                    @current-change="handleCurrentChangeZb"
                    :current-page="currentzbPage"
                    :page-size="pageSizezb"
                    layout="prev, pager, next"
                    :page-count="totalzb"
                    :total="totaldatazb">
      </el-pagination>
    <br/>
    
    <el-dialog :title="dialogTitle"
               :visible.sync="addzhibiao"
               v-model="addzhibiao"
               :close-on-click-modal="false"
               center
               >
      <el-form ref="addzhibiaoForm" :model="addzhibiaoForm" label-width="80px" :rules="addzhibiaoFormRules">
        <el-form-item label="配置名称" prop="TemplateName">
          <el-input v-model="addzhibiaoForm.TemplateName" placeholder="简述配置名称 如：流程批复指标"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="Remark">
          <el-input v-model="addzhibiaoForm.Remark" placeholder=""></el-input>
        </el-form-item>
        <!-- <el-form-item label="是否公开" prop="Public">
          <el-radio-group v-model="addzhibiaoForm.IsPublic" size="mini">
            <el-radio :label="false">私有</el-radio>
            <el-radio :label="true">公开</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="创建人：" prop="CreatedBy">
          <span style="font-size:9px;font-weight:bold;">{{addzhibiaoForm.CreatedByStr}}</span>
        </el-form-item>
        <el-button-group>
          <el-button type="default" size="mini" icon="el-icon-plus" @click="handleAddZhibiaoLine(-1)">添加</el-button>
          <el-button type="default" size="mini" icon="el-icon-minus" @click="handleDelZhibiaoLine()">删除选中</el-button>
        </el-button-group>
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          align="center"
          max-height="400"
          :data="zhibiaoDetail"
          :edit-rules="validRules"
          :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil', showStatus: true}">
          <vxe-column type="checkbox" width="60"></vxe-column>
          <vxe-column type="seq" width="50" title="序号"></vxe-column>
          <vxe-column field="name" title="指标项" min-width="100px" :edit-render="{autofocus: '.vxe-input--inner', defaultValue: '例：流程数量'}">
            <template #edit="{ row }">
              <vxe-input v-model="row.name" type="text" maxlength="35"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="type" title="值类型" :edit-render="{defaultValue:1}">
            <template #default="{ row }">
              <span>{{ formatType(row.type) }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select v-model="row.type" transfer align='center'>
                <vxe-option v-for="item in typeList" :key="item.value" :value="item.value" :label="item.label"></vxe-option>
              </vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="length" title="值长度"  :edit-render="{defaultValue: 10}">
            <template #edit="{ row }">
              <vxe-input v-model="row.length" min="1" align="center" max="50" type="integer"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="unit" title="值单位" :edit-render="{autofocus: '.vxe-input--inner', defaultValue: '支'}">
            <template #edit="{ row }">
              <vxe-input v-model="row.unit" type="text" maxlength="5"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="mustinput" title="值必填" :edit-render="{defaultValue:true}">
            <template #default="{ row }">
              <span>{{ formatMustinput(row.mustinput) }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select v-model="row.mustinput" transfer>
                <vxe-option v-for="item in mustinputList" :key="item.value" :value="item.value" :label="item.label"></vxe-option>
              </vxe-select>
            </template>
          </vxe-column>
          <!-- <vxe-column field="default" title="默认值" :edit-render="{autofocus: '.vxe-input--inner', defaultValue: ''}">
            <template #edit="{ row }">
              <vxe-input v-model="row.default" type="text" maxlength="50"></vxe-input>
            </template>
          </vxe-column> -->
          <!-- <vxe-column field="date12" title="日期" sortable :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.date12" type="date" transfer></vxe-input>
            </template>
          </vxe-column> -->
        </vxe-table>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSubmitTemplate">提交</el-button>
        <el-button type="default" @click.native=" addzhibiao = false ">关闭</el-button>
      </div>
    </el-dialog>

  </section>
</template>
<script>
import util from "../../../util/date";
import {
  AddTemplate,UpdateTemplate,DelTemplate,RenameTemplate,ChangeTemplatePublicStatus,QueryPageTemplateByUserCode,QueryTemplateDetailByTemplateId
} from "../../api/oa";
import {Loading} from 'element-ui'
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      buttonList: [],
      filters: {
        name: ""
      },
      RegularMeetingmsg: "zhibiaoku",
      dialogTitle:'新增指标配置',
      loading: '',
      currentzbPage: 1, // 当前页码
      totalzb: null, // 总条数
      totaldatazb: null,
      pageSizezb: 5, // 每页的数据条数
      pagezb: 1,
      currentRow: null,
      currentZhibiaoRow: null,
      zhibiaolist:[],
      lookdetail:[],
      zhibiaopeizhi: false,
      zhibiaoDetail:[],
      addzhibiao: false,
      addzhibiaoForm:{
        TemplateName:'',
        Remark:'',
        IsPublic:true,
        CreatedBy:''
      },
      typeList: [
        { label: '数字', value: 1 },
        { label: '文本', value: 2 },
        // { label: '日期', value: 3 },
        // { label: '时间', value: 4 }
      ],
      mustinputList:[
        { label: '必填', value: true },
        { label: '非必填', value: false },
      ],
      radioPublic:true,
      addzhibiaoFormRules:{
        TemplateName: [
          { required: true, message: '请填写指标配置名称', trigger: 'blur' }
        ],
        IsPublic: [
          { required: true, message: '请选择是否公开', trigger: 'blur' }
        ],
      },
      validRules: {
        name: [
          { required: true, message: '指标项必须填写' },
        ],
        // unit: [
        //   { required: true, message: '指标值单位必须填写' },
        // ],
      },
    };
  },
  methods: {
    
    handleChoose(row)
    {
      // console.log(row);
      this.$emit('chooseCallFunction', row)
    },
    formatMustinput (value) {
      for(var a=0;a<this.mustinputList.length;a++)
      {
        if(this.mustinputList[a].value==value)
        {
          return this.mustinputList[a].label;
        }
      }
      return ''
    },
    formatType (value) {
      for(var a=0;a<this.typeList.length;a++)
      {
        if(this.typeList[a].value==value)
        {
          return this.typeList[a].label;
        }
      }
      return ''
    },
    handleSubmitTemplate()
    {
      this.$refs.addzhibiaoForm.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请完整填写表单',
            type: 'warning'
          });
          return;
        }else{
          const $table = this.$refs.xTable
          var tableData = $table.getTableData().tableData;
          // console.log(tableData);
          
          if(tableData.length==0)
          {
              this.$message({
                message: '请填写指标配置项',
                type: 'warning'
              });
              return;
          }
          var iserr = false;
          for(var a=0;a< tableData.length;a++)
          {
            if(!tableData[a].name.trim())
            {
              this.$message({
                message: '请完整填写指标配置项',
                type: 'warning'
              });
              iserr = true;
              break;
            }
          }
          
          if(!iserr)
          {
            this.$confirm("确定要提交此指标配置吗？", "提示", {}).then(() => {
              var para =Object.assign({},this.addzhibiaoForm);
              var detail = new Array();
              tableData.forEach(element => {
                var obj={
                  Id:element.id,
                  FieldName:element.name,
                  FieldUnit:element.unit,
                  FieldType:element.type,
                  FieldLength:element.length,
                  FieldMustInput:element.mustinput,
                  // FieldDefault:element.default
                }
                detail.push(obj);
              });
              
              para.Details=detail;
              console.log(para)
              
              // return;
              if(this.addzhibiaoForm.Id)
              {
                UpdateTemplate(para).then((res) => {
                  // console.log(res);
                  if(res.status==200)
                  {
                    this.$message({
                      message: '修改指标配置成功',
                      type: 'success'
                    });
                    this.addzhibiao = false;
                    this.radioPublic = this.addzhibiaoForm.IsPublic;
                    this.queryTemplateList();
                  }
                })
              }else{
                AddTemplate(para).then((res) => {
                  // console.log(res);
                  if(res.status==200)
                  {
                    this.$message({
                      message: '新增指标配置成功',
                      type: 'success'
                    });
                    this.addzhibiao = false;
                    this.radioPublic = this.addzhibiaoForm.IsPublic;
                    this.queryTemplateList();
                  }
                })
              }
            }).catch(()=>{});
          }
        }
      })
    },
    handleDelZhibiaoLine()
    {
      const xtable = this.$refs.xTable;
      xtable.removeCheckboxRow();
    },
    handleAddZhibiaoLine(row)
    {
      const xtable = this.$refs.xTable;
      const record = {};
      const { row: newRow } = xtable.insertAt(record, row)
      xtable.setActiveCell(newRow, 'name')
    },
    handleEdit()
    {
      let row = this.currentZhibiaoRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的指标配置",
          type: "warning",
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前登录人非指标配置创建者，无法编辑！",
          type: "warning",
        });
        return;
      }
      // if(row.UseCount>0)
      // {
      //   this.$message({
      //     message: '此指标已有任务使用，无需编辑',
      //     type: 'warning'
      //   });
      //   return;
      // }
      this.dialogTitle = '编辑指标配置';
      this.addzhibiao = true;
      this.addzhibiaoForm.Id = row.Id;
      this.addzhibiaoForm.TemplateName = row.TemplateName;
      this.addzhibiaoForm.Remark = row.Remark;
      this.addzhibiaoForm.CreatedByStr = row.CreatedBy;

      let para = { templateId: row.Id };
      QueryTemplateDetailByTemplateId(para).then((res) => {
        // console.log(res.data.response)
        var arr = new Array();
        res.data.response.forEach(element => {
          arr.push({
            id:element.Id,
            name:element.FieldName,
            type:element.FieldType,
            length:element.FieldLength,
            unit:element.FieldUnit,
            mustinput:element.FieldMustInput
          })
        });
        this.zhibiaoDetail = arr;
      });
    },
    handleAdd()
    {
      this.dialogTitle = '新增指标配置';
      this.addzhibiaoForm.TemplateName = '';
      this.addzhibiaoForm.Remark = '';
      this.zhibiaoDetail = [];
      this.addzhibiao = true;
    },
    handleDel()
    {
      let row = this.currentZhibiaoRow;
      if (!row) {
        this.$message({
          message: "请选择要移除的指标配置",
          type: "warning",
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前登录人非指标配置创建者，无法删除！",
          type: "warning",
        });
        return;
      }
      // if(row.UseCount>0)
      // {
      //   this.$message({
      //     message: '此指标已有任务使用，无法删除',
      //     type: 'warning'
      //   });
      //   return;
      // }

      this.$confirm("确定要将选中的指标配置删除吗？", "提示", {}).then(() => {
        var templateId = row.Id;
        let para = { templateId: templateId };
        DelTemplate(para).then((res) => {
          // console.log(res);
          if(res.status==200)
          {
            this.$message({
              message: '删除指标配置成功',
              type: 'success'
            });
            this.queryTemplateList();
          }
        })
      }).catch(()=>{});
    },
    handleZhibiao()
    {
      var user = JSON.parse(window.localStorage.user);
      this.addzhibiaoForm.CreatedId = user.sub;
      this.addzhibiaoForm.CreatedBy = user.name;
      this.addzhibiaoForm.CreatedDptId = window.localStorage.deptId;
      this.addzhibiaoForm.CreatedDpt = window.localStorage.deptName;
      this.addzhibiaoForm.CreatedByStr=this.addzhibiaoForm.CreatedDpt+'-'+user.name;
      this.zhibiaopeizhi = true;
      this.queryTemplateList();
    },
    handleQuery()
    {
      this.queryTemplateList();
    },
    queryTemplateList()
    {
      var user = JSON.parse(window.localStorage.user);
      let para = { 
        userCode: user.sub,
        ispublic:this.radioPublic,
        searchKey:this.filters.name,
        pageIndex:this.pagezb,
        pageSize:this.pageSizezb
      };
      QueryPageTemplateByUserCode(para).then((res) => {
        // console.log(res)
        this.totalzb = res.data.response.pageCount;
        this.totaldatazb = res.data.response.dataCount;
        var data = res.data.response.data;
        // console.log(data)
        this.zhibiaolist = data;
      });
    },
    handleLookTemplateDetail(templateId)
    {
      let para = { templateId: templateId };
      QueryTemplateDetailByTemplateId(para).then((res) => {
        // console.log(res)
        this.lookdetail = res.data.response;
      });
    },
    handleRename()
    {
      let row = this.currentZhibiaoRow;
      if (!row) {
        this.$message({
          message: "请选择要重命名的指标配置",
          type: "warning",
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前登录人非指标配置创建者，无法删除！",
          type: "warning",
        });
        return;
      }
      var templateId = row.Id;
      this.$prompt('输入新配置名称', '指标配置重命名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:row.TemplateName
      }).then(({ value }) => {
        row.TemplateName = value;

        let para = { templateId: templateId,newname:value };
        RenameTemplate(para).then((res) => {
          // console.log(res);
          if(res.status==200)
          {
            this.$message({
              message: '配置名称已修改',
              type: 'success'
            });
            this.queryTemplateList();
          }
        })
      }).catch(() => { });
    },
    handleChangeZhibiaoStatus()
    {
      let row = this.currentZhibiaoRow;
      if (!row) {
        this.$message({
          message: "请选择要调整的指标配置",
          type: "warning",
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前登录人非指标配置创建者，无法调整状态！",
          type: "warning",
        });
        return;
      }
      var status = row.IsPublic?'私有':'公开';
      this.$confirm("确定要将此指标调整为「"+status+"」状态吗？", "提示", {}).then(() => {
        var templateId = row.Id;
        let para = { templateId: templateId,ispublic:!row.IsPublic };
        ChangeTemplatePublicStatus(para).then((res) => {
          // console.log(res);
          if(res.status==200)
          {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.queryTemplateList();
          }
        })
      }).catch(()=>{});

    },
    handleRefreshZhibiaolist()
    {
      this.pagezb = 1;
      this.queryTemplateList();
    },
    selectCurrentZhibiaoRow (val)
    {
      this.currentZhibiaoRow = val;
    },
    handleCurrentChangeZb (val) {
      this.pagezb = val;
      this.queryTemplateList();
    },
    myname() {
      var user = JSON.parse(window.localStorage.user)
      return user.name
    },
    checkclose() {
      this.pValue1 = 0
      this.$emit('closeFunction', {})
    },
    Loadingstart() {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend() {
      this.loading.close()
    },
    callFunction(item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
  },
  mounted() {
    
    var user = JSON.parse(window.localStorage.user);
    this.addzhibiaoForm.CreatedId = user.sub;
    this.addzhibiaoForm.CreatedBy = user.name;
    this.addzhibiaoForm.CreatedDptId = window.localStorage.deptId;
    this.addzhibiaoForm.CreatedDpt = window.localStorage.deptName;
    this.addzhibiaoForm.CreatedByStr=this.addzhibiaoForm.CreatedDpt+'-'+user.name;
    this.zhibiaopeizhi = true;
    this.queryTemplateList();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);
    
    //第二种写法，封装到 permissionRouter.js 中
    let  buttons = window.localStorage.buttList? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers,buttons);
  }
};
</script>